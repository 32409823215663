/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { FinancialReportInputPayload } from '../models/financial-report-input-payload';
import { LasgroFinancial_report } from '../models/lasgro-financial-_report';
import { LasgroPanda_doc_document } from '../models/lasgro-panda-_doc-_document';
import { LasgroFinancial_reportCollection } from '../models/lasgro-financial-_report-collection';
@Injectable({
  providedIn: 'root',
})
class FinancialReportService extends __BaseService {
  static readonly financialReportAddPath = '/purchase_order/financial_report';
  static readonly financialReportSavePath = '/purchase_order/financial_report/save';
  static readonly financialReportShowPath = '/purchase_order/financial_report/{financialReportID}';
  static readonly financialReportDeletePath = '/purchase_order/financial_report/{financialReportID}';
  static readonly financialReportFinancialReportOpenedFirstTimePath = '/purchase_order/{purchaseOrderID}/financial_report/{financialReportID}/financial_report_opened_first_time';
  static readonly financialReportSendFinancialReportPath = '/purchase_order/{purchaseOrderID}/financial_report/{financialReportID}/send_financial_report';
  static readonly financialReportSendVoucherPath = '/purchase_order/{purchaseOrderID}/financial_report/{financialReportID}/send_voucher';
  static readonly financialReportSubmitPath = '/purchase_order/{purchaseOrderID}/financial_report/{financialReportID}/submit';
  static readonly financialReportVoucherOpenedFirstTimePath = '/purchase_order/{purchaseOrderID}/financial_report/{financialReportID}/voucher_opened_first_time';
  static readonly financialReportListPath = '/purchase_order/{purchaseOrderID}/financial_reports';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * add financial_report
   *
   * Add a new financial report
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload undefined
   */
  financialReportAddResponse(payload: FinancialReportInputPayload): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/purchase_order/financial_report`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * add financial_report
   *
   * Add a new financial report
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload undefined
   */
  financialReportAdd(payload: FinancialReportInputPayload): __Observable<null> {
    return this.financialReportAddResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Save financial_report
   *
   * Save new financial report
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload undefined
   */
  financialReportSaveResponse(payload: FinancialReportInputPayload): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/purchase_order/financial_report/save`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Save financial_report
   *
   * Save new financial report
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload undefined
   */
  financialReportSave(payload: FinancialReportInputPayload): __Observable<null> {
    return this.financialReportSaveResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * show financial_report
   *
   * Get financial report by id
   *
   * Required security scopes:
   *   * `api:access`
   * @param financialReportID Financial Report ID
   * @return OK
   */
  financialReportShowResponse(financialReportID: string): __Observable<__StrictHttpResponse<LasgroFinancial_report>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/purchase_order/financial_report/${encodeURIComponent(String(financialReportID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroFinancial_report>;
      })
    );
  }
  /**
   * show financial_report
   *
   * Get financial report by id
   *
   * Required security scopes:
   *   * `api:access`
   * @param financialReportID Financial Report ID
   * @return OK
   */
  financialReportShow(financialReportID: string): __Observable<LasgroFinancial_report> {
    return this.financialReportShowResponse(financialReportID).pipe(
      __map(_r => _r.body as LasgroFinancial_report)
    );
  }

  /**
   * delete financial_report
   *
   * Delete financial report by id
   *
   * Required security scopes:
   *   * `api:access`
   * @param financialReportID Financial Report ID
   */
  financialReportDeleteResponse(financialReportID: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/purchase_order/financial_report/${encodeURIComponent(String(financialReportID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * delete financial_report
   *
   * Delete financial report by id
   *
   * Required security scopes:
   *   * `api:access`
   * @param financialReportID Financial Report ID
   */
  financialReportDelete(financialReportID: string): __Observable<null> {
    return this.financialReportDeleteResponse(financialReportID).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * FinancialReportOpenedFirstTime financial_report
   *
   * Financial report opened first time
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `FinancialReportService.FinancialReportFinancialReportOpenedFirstTimeParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `financialReportID`:
   */
  financialReportFinancialReportOpenedFirstTimeResponse(params: FinancialReportService.FinancialReportFinancialReportOpenedFirstTimeParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/purchase_order/${encodeURIComponent(String(params.purchaseOrderID))}/financial_report/${encodeURIComponent(String(params.financialReportID))}/financial_report_opened_first_time`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * FinancialReportOpenedFirstTime financial_report
   *
   * Financial report opened first time
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `FinancialReportService.FinancialReportFinancialReportOpenedFirstTimeParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `financialReportID`:
   */
  financialReportFinancialReportOpenedFirstTime(params: FinancialReportService.FinancialReportFinancialReportOpenedFirstTimeParams): __Observable<null> {
    return this.financialReportFinancialReportOpenedFirstTimeResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * SendFinancialReport financial_report
   *
   * Upload financial report file
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `FinancialReportService.FinancialReportSendFinancialReportParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `financialReportID`:
   *
   * - `file`: uploaded file
   *
   * @return OK
   */
  financialReportSendFinancialReportResponse(params: FinancialReportService.FinancialReportSendFinancialReportParams): __Observable<__StrictHttpResponse<LasgroPanda_doc_document>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;


    if (params.file != null) { __formData.append('file', params.file as string | Blob);}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/purchase_order/${encodeURIComponent(String(params.purchaseOrderID))}/financial_report/${encodeURIComponent(String(params.financialReportID))}/send_financial_report`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroPanda_doc_document>;
      })
    );
  }
  /**
   * SendFinancialReport financial_report
   *
   * Upload financial report file
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `FinancialReportService.FinancialReportSendFinancialReportParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `financialReportID`:
   *
   * - `file`: uploaded file
   *
   * @return OK
   */
  financialReportSendFinancialReport(params: FinancialReportService.FinancialReportSendFinancialReportParams): __Observable<LasgroPanda_doc_document> {
    return this.financialReportSendFinancialReportResponse(params).pipe(
      __map(_r => _r.body as LasgroPanda_doc_document)
    );
  }

  /**
   * SendVoucher financial_report
   *
   * Upload voucher file
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `FinancialReportService.FinancialReportSendVoucherParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `financialReportID`:
   *
   * - `file`: uploaded file
   *
   * @return OK
   */
  financialReportSendVoucherResponse(params: FinancialReportService.FinancialReportSendVoucherParams): __Observable<__StrictHttpResponse<LasgroPanda_doc_document>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;


    if (params.file != null) { __formData.append('file', params.file as string | Blob);}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/purchase_order/${encodeURIComponent(String(params.purchaseOrderID))}/financial_report/${encodeURIComponent(String(params.financialReportID))}/send_voucher`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroPanda_doc_document>;
      })
    );
  }
  /**
   * SendVoucher financial_report
   *
   * Upload voucher file
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `FinancialReportService.FinancialReportSendVoucherParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `financialReportID`:
   *
   * - `file`: uploaded file
   *
   * @return OK
   */
  financialReportSendVoucher(params: FinancialReportService.FinancialReportSendVoucherParams): __Observable<LasgroPanda_doc_document> {
    return this.financialReportSendVoucherResponse(params).pipe(
      __map(_r => _r.body as LasgroPanda_doc_document)
    );
  }

  /**
   * Submit financial_report
   *
   * Upload financial report file
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `FinancialReportService.FinancialReportSubmitParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `financialReportID`:
   */
  financialReportSubmitResponse(params: FinancialReportService.FinancialReportSubmitParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/purchase_order/${encodeURIComponent(String(params.purchaseOrderID))}/financial_report/${encodeURIComponent(String(params.financialReportID))}/submit`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Submit financial_report
   *
   * Upload financial report file
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `FinancialReportService.FinancialReportSubmitParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `financialReportID`:
   */
  financialReportSubmit(params: FinancialReportService.FinancialReportSubmitParams): __Observable<null> {
    return this.financialReportSubmitResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * VoucherOpenedFirstTime financial_report
   *
   * Voucher opened first time
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `FinancialReportService.FinancialReportVoucherOpenedFirstTimeParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `financialReportID`:
   */
  financialReportVoucherOpenedFirstTimeResponse(params: FinancialReportService.FinancialReportVoucherOpenedFirstTimeParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/purchase_order/${encodeURIComponent(String(params.purchaseOrderID))}/financial_report/${encodeURIComponent(String(params.financialReportID))}/voucher_opened_first_time`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * VoucherOpenedFirstTime financial_report
   *
   * Voucher opened first time
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `FinancialReportService.FinancialReportVoucherOpenedFirstTimeParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `financialReportID`:
   */
  financialReportVoucherOpenedFirstTime(params: FinancialReportService.FinancialReportVoucherOpenedFirstTimeParams): __Observable<null> {
    return this.financialReportVoucherOpenedFirstTimeResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * list financial_report
   *
   * Lists financial reports
   *
   * Required security scopes:
   *   * `api:access`
   * @param purchaseOrderID Purchase order ID
   * @return OK
   */
  financialReportListResponse(purchaseOrderID: string): __Observable<__StrictHttpResponse<LasgroFinancial_reportCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/purchase_order/${encodeURIComponent(String(purchaseOrderID))}/financial_reports`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroFinancial_reportCollection>;
      })
    );
  }
  /**
   * list financial_report
   *
   * Lists financial reports
   *
   * Required security scopes:
   *   * `api:access`
   * @param purchaseOrderID Purchase order ID
   * @return OK
   */
  financialReportList(purchaseOrderID: string): __Observable<LasgroFinancial_reportCollection> {
    return this.financialReportListResponse(purchaseOrderID).pipe(
      __map(_r => _r.body as LasgroFinancial_reportCollection)
    );
  }
}

module FinancialReportService {

  /**
   * Parameters for financialReportFinancialReportOpenedFirstTime
   */
  export interface FinancialReportFinancialReportOpenedFirstTimeParams {
    purchaseOrderID: string;
    financialReportID: string;
  }

  /**
   * Parameters for financialReportSendFinancialReport
   */
  export interface FinancialReportSendFinancialReportParams {
    purchaseOrderID: string;
    financialReportID: string;

    /**
     * uploaded file
     */
    file: Blob;
  }

  /**
   * Parameters for financialReportSendVoucher
   */
  export interface FinancialReportSendVoucherParams {
    purchaseOrderID: string;
    financialReportID: string;

    /**
     * uploaded file
     */
    file: Blob;
  }

  /**
   * Parameters for financialReportSubmit
   */
  export interface FinancialReportSubmitParams {
    purchaseOrderID: string;
    financialReportID: string;
  }

  /**
   * Parameters for financialReportVoucherOpenedFirstTime
   */
  export interface FinancialReportVoucherOpenedFirstTimeParams {
    purchaseOrderID: string;
    financialReportID: string;
  }
}

export { FinancialReportService }
