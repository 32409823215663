/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LasgroGeneral_success_payload } from '../models/lasgro-general-_success-_payload';
import { SNSInputPayload } from '../models/snsinput-payload';
import { DocumentStatusWebhookExternalTaskPayload } from '../models/document-status-webhook-external-task-payload';
import { LasgroExternal_task_instance } from '../models/lasgro-external-_task-_instance';
import { ExternalTaskActionInputPayload } from '../models/external-task-action-input-payload';
@Injectable({
  providedIn: 'root',
})
class ExternalTaskService extends __BaseService {
  static readonly externalTaskListenSnsPath = '/externals/api/sns';
  static readonly externalTaskDocumentStatusWebhookPath = '/externals/document_status_webhook';
  static readonly externalTaskInstanceShowPath = '/externals/tasks/{taskID}/instances/{taskInstanceID}';
  static readonly externalTaskActionPath = '/externals/workflows/{workflowID}/instances/{workflowInstanceID}/tasks/{taskID}/instances/{taskInstanceID}/action';
  static readonly externalTaskUploadActionPath = '/externals/workflows/{workflowID}/instances/{workflowInstanceID}/tasks/{taskID}/instances/{taskInstanceID}/upload/action';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * listen_sns external_task
   *
   * detects an sns event
   * @param payload The request payload for sns events
   * @return OK
   */
  externalTaskListenSnsResponse(payload: SNSInputPayload): __Observable<__StrictHttpResponse<LasgroGeneral_success_payload>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/externals/api/sns`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroGeneral_success_payload>;
      })
    );
  }
  /**
   * listen_sns external_task
   *
   * detects an sns event
   * @param payload The request payload for sns events
   * @return OK
   */
  externalTaskListenSns(payload: SNSInputPayload): __Observable<LasgroGeneral_success_payload> {
    return this.externalTaskListenSnsResponse(payload).pipe(
      __map(_r => _r.body as LasgroGeneral_success_payload)
    );
  }

  /**
   * DocumentStatusWebhook external_task
   *
   * Check pandadoc document status
   * @param payload undefined
   * @return OK
   */
  externalTaskDocumentStatusWebhookResponse(payload: DocumentStatusWebhookExternalTaskPayload): __Observable<__StrictHttpResponse<LasgroGeneral_success_payload>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/externals/document_status_webhook`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroGeneral_success_payload>;
      })
    );
  }
  /**
   * DocumentStatusWebhook external_task
   *
   * Check pandadoc document status
   * @param payload undefined
   * @return OK
   */
  externalTaskDocumentStatusWebhook(payload: DocumentStatusWebhookExternalTaskPayload): __Observable<LasgroGeneral_success_payload> {
    return this.externalTaskDocumentStatusWebhookResponse(payload).pipe(
      __map(_r => _r.body as LasgroGeneral_success_payload)
    );
  }

  /**
   * instance_show external_task
   *
   * Returns a single task instance for external user
   * @param params The `ExternalTaskService.ExternalTaskInstanceShowParams` containing the following parameters:
   *
   * - `taskInstanceID`:
   *
   * - `taskID`:
   *
   * - `email`:
   *
   * @return OK
   */
  externalTaskInstanceShowResponse(params: ExternalTaskService.ExternalTaskInstanceShowParams): __Observable<__StrictHttpResponse<LasgroExternal_task_instance>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.email != null) __headers = __headers.set('email', params.email.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/externals/tasks/${encodeURIComponent(String(params.taskID))}/instances/${encodeURIComponent(String(params.taskInstanceID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroExternal_task_instance>;
      })
    );
  }
  /**
   * instance_show external_task
   *
   * Returns a single task instance for external user
   * @param params The `ExternalTaskService.ExternalTaskInstanceShowParams` containing the following parameters:
   *
   * - `taskInstanceID`:
   *
   * - `taskID`:
   *
   * - `email`:
   *
   * @return OK
   */
  externalTaskInstanceShow(params: ExternalTaskService.ExternalTaskInstanceShowParams): __Observable<LasgroExternal_task_instance> {
    return this.externalTaskInstanceShowResponse(params).pipe(
      __map(_r => _r.body as LasgroExternal_task_instance)
    );
  }

  /**
   * action external_task
   *
   * detects an action performed on a task
   * @param params The `ExternalTaskService.ExternalTaskActionParams` containing the following parameters:
   *
   * - `workflowInstanceID`:
   *
   * - `workflowID`:
   *
   * - `taskInstanceID`:
   *
   * - `taskID`:
   *
   * - `payload`: The request payload for whenever an action is performed on a task by an external user
   *
   * - `email`:
   *
   * @return OK
   */
  externalTaskActionResponse(params: ExternalTaskService.ExternalTaskActionParams): __Observable<__StrictHttpResponse<LasgroGeneral_success_payload>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;




    __body = params.payload;
    if (params.email != null) __headers = __headers.set('email', params.email.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/externals/workflows/${encodeURIComponent(String(params.workflowID))}/instances/${encodeURIComponent(String(params.workflowInstanceID))}/tasks/${encodeURIComponent(String(params.taskID))}/instances/${encodeURIComponent(String(params.taskInstanceID))}/action`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroGeneral_success_payload>;
      })
    );
  }
  /**
   * action external_task
   *
   * detects an action performed on a task
   * @param params The `ExternalTaskService.ExternalTaskActionParams` containing the following parameters:
   *
   * - `workflowInstanceID`:
   *
   * - `workflowID`:
   *
   * - `taskInstanceID`:
   *
   * - `taskID`:
   *
   * - `payload`: The request payload for whenever an action is performed on a task by an external user
   *
   * - `email`:
   *
   * @return OK
   */
  externalTaskAction(params: ExternalTaskService.ExternalTaskActionParams): __Observable<LasgroGeneral_success_payload> {
    return this.externalTaskActionResponse(params).pipe(
      __map(_r => _r.body as LasgroGeneral_success_payload)
    );
  }

  /**
   * upload_action external_task
   *
   * detects an upload action performed on a task
   * @param params The `ExternalTaskService.ExternalTaskUploadActionParams` containing the following parameters:
   *
   * - `workflowInstanceID`:
   *
   * - `workflowID`:
   *
   * - `taskInstanceID`:
   *
   * - `taskID`:
   *
   * - `file`: uploaded_file
   *
   * - `email`:
   *
   * @return OK
   */
  externalTaskUploadActionResponse(params: ExternalTaskService.ExternalTaskUploadActionParams): __Observable<__StrictHttpResponse<LasgroGeneral_success_payload>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;




    if (params.file != null) { __formData.append('file', params.file as string | Blob);}
    if (params.email != null) __headers = __headers.set('email', params.email.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/externals/workflows/${encodeURIComponent(String(params.workflowID))}/instances/${encodeURIComponent(String(params.workflowInstanceID))}/tasks/${encodeURIComponent(String(params.taskID))}/instances/${encodeURIComponent(String(params.taskInstanceID))}/upload/action`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroGeneral_success_payload>;
      })
    );
  }
  /**
   * upload_action external_task
   *
   * detects an upload action performed on a task
   * @param params The `ExternalTaskService.ExternalTaskUploadActionParams` containing the following parameters:
   *
   * - `workflowInstanceID`:
   *
   * - `workflowID`:
   *
   * - `taskInstanceID`:
   *
   * - `taskID`:
   *
   * - `file`: uploaded_file
   *
   * - `email`:
   *
   * @return OK
   */
  externalTaskUploadAction(params: ExternalTaskService.ExternalTaskUploadActionParams): __Observable<LasgroGeneral_success_payload> {
    return this.externalTaskUploadActionResponse(params).pipe(
      __map(_r => _r.body as LasgroGeneral_success_payload)
    );
  }
}

module ExternalTaskService {

  /**
   * Parameters for externalTaskInstanceShow
   */
  export interface ExternalTaskInstanceShowParams {
    taskInstanceID: string;
    taskID: string;
    email: string;
  }

  /**
   * Parameters for externalTaskAction
   */
  export interface ExternalTaskActionParams {
    workflowInstanceID: string;
    workflowID: string;
    taskInstanceID: string;
    taskID: string;

    /**
     * The request payload for whenever an action is performed on a task by an external user
     */
    payload: ExternalTaskActionInputPayload;
    email: string;
  }

  /**
   * Parameters for externalTaskUploadAction
   */
  export interface ExternalTaskUploadActionParams {
    workflowInstanceID: string;
    workflowID: string;
    taskInstanceID: string;
    taskID: string;

    /**
     * uploaded_file
     */
    file: Blob;
    email: string;
  }
}

export { ExternalTaskService }
